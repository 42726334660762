<template>
  <div class="product-choose">
    <a-modal
      title="选择商品"
      :maskClosable="false"
      :visible="visibleTable"
      :confirm-loading="confirmLoading"
      @ok="handleOk"
      @cancel="handleCancel"
      class="product-choose-modal"
      width="73%"
    >
      <a-form-model ref="searchData" :label-col="{ xs: 6 }" :wrapper-col="{ xs: 18 }">
        <a-row :gutter="8">
          <a-col :md="8" :xl="8" :xxl="8" :sm="24">
            <a-form-model-item label="商品名称">
              <a-input v-model="searchData.name" placeholder="请输入商品名称"></a-input>
            </a-form-model-item>
          </a-col>
          <a-col :md="8" :xl="8" :xxl="8" :sm="24">
            <a-form-model-item label="商品分类">
              <a-cascader
                :options="categoryList"
                :value="categorySelectData"
                style="width: 100%"
                :field-names="{ label: 'name', value: 'id', children: 'children' }"
                placeholder="请选择"
                @change="onCategoryChange"
              />
            </a-form-model-item>
          </a-col>
          <a-col :md="8" :xl="8" :xxl="8" :sm="24">
            <a-form-model-item label="商品品牌" :label-col="{ xs: 8 }" :wrapper-col="{ xs: 16 }">
              <a-select v-model="searchData.brandId" placeholder="请选择商品品牌">
                <a-select-option :value="item.id" v-for="(item, index) in proBrandList" :key="index">
                  {{ item.ch_name }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :md="8" :xl="8" :xxl="8" :sm="24">
            <a-form-model-item label="标准开票价（D价）">
              <a-input-number
                placeholder=""
                :formatter="(value) => `￥ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')"
                :parser="(value) => value.replace(/\￥\s?|(,*)/g, '')"
                v-model="searchData.startPrice"
                :min="1"
                :max="999999"
              />~
              <a-input-number
                placeholder=""
                :formatter="(value) => `￥ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')"
                :parser="(value) => value.replace(/\￥\s?|(,*)/g, '')"
                v-model="searchData.endPrice"
                :min="1"
                :max="999999"
              />
            </a-form-model-item>
          </a-col>
          <a-col :md="8" :xl="8" :xxl="8" :sm="24">
            <a-form-model-item :label-col="{ span: 0 }" :wrapper-col="{ span: 24 }">
              <a-button type="primary" @click="getList(3)" icon="search">查询</a-button>
              <a-button type="default" @click="getList(2)" icon="sync">重置</a-button>
            </a-form-model-item>
          </a-col>
        </a-row>
      </a-form-model>
      <a-table
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
        :columns="columnsChoose"
        :data-source="productList"
        :pagination="page"
        @change="changeTable"
        bordered
      >
        <span slot="name" slot-scope="text">
          <a-tooltip>
            <template slot="title"> {{ text }} </template> {{ text }}
          </a-tooltip>
        </span>
        <span slot="price" slot-scope="text"> {{ text | formatMoney }} </span>
        <span slot="sellPrice" slot-scope="text"> {{ text | formatMoney }} </span>
        <span slot="imgUrl" slot-scope="item">
          <ZoomMedia style="height: 20px" :value="item"></ZoomMedia>
        </span>
      </a-table>
    </a-modal>
  </div>
</template>

<script>
export default {
  data() {
    return {
      columnsChoose: [
        {
          title: '商品图片',
          width: 100,
          dataIndex: 'imgUrl',
          align: 'center',
          scopedSlots: { customRender: 'imgUrl' },
        },
        {
          title: '商品名称',
          dataIndex: 'name',
          width: 300,
          ellipsis: true,
          scopedSlots: { customRender: 'name' },
        },
        {
          title: '品牌',
          dataIndex: 'brandName',
          ellipsis: true,
        },

        {
          title: '分类',
          dataIndex: 'categoryName',
          key: 'categoryName',
          align: 'center',
          ellipsis: true,
        },
        {
          title: '规格',
          dataIndex: 'specStr',
          ellipsis: true,
        },
        // {
        //   title: '建议零售价',
        //   dataIndex: 'price',
        //   // ellipsis:true,
        //   scopedSlots: { customRender: 'price' },
        // },
        {
          title: '标准开票价（D价）',
          dataIndex: 'sellPrice',
          // ellipsis:true,
          scopedSlots: { customRender: 'sellPrice' },
        },
      ],
      visibleTable: false,
      confirmLoading: false,
      productList: [],
      selectedRowKeys: [],
      options: [],
      searchData: {}, // 搜索条件
      item: [],
      page: {
        pageNumber: 1,
        pageSize: 10,
        total: 0,
      },
      categoryList: [],
      categorySelectData: [],
      proBrandList: [],
    }
  },
  computed: {
    hasSelected() {
      return this.selectedRowKeys.length > 0
    },
  },

  created() {
    this.axios.get('/api/product/category/categoryList?flagEnable=true').then((res) => {
      this.categoryList = res.body[0] ? res.body[0].children : []
    })
    this.axios.get('/api/product/system/brand/All/list').then((res) => {
      this.proBrandList = res.body
    })
  },

  methods: {
    isShow() {
      this.visibleTable = true
      this.item = []
      this.selectedRowKeys = []
      this.getList(1)
    },

    getList(type) {
      if (type == 2) {
        this.searchData = {}
      }
      if (type == 3) {
        this.page.pageNumber = 1
      }
      const data = Object.assign(
        {},
        {
          pageNumber: this.page.pageNumber,
          pageSize: this.page.pageSize,
          ...this.searchData,
        }
      )
      this.axios.get('/api/product/system/goods/listBySetting', { params: data }).then((res) => {
        this.productList = res.body.records
        this.page.total = res.body.total
      })
    },

    showModal() {
      this.visibleTable = true
    },

    handleOk(e) {
      this.$emit('get-pruduct-list', this.item)
      this.item = []
      this.visibleTable = false
    },

    handleCancel(e) {
      this.visibleTable = false
    },

    start() {
      this.loading = true
      setTimeout(() => {
        this.loading = false
        this.selectedRowKeys = []
      }, 1000)
    },

    changeTable(pagination) {
      this.page = pagination
      this.page.pageNumber = pagination.current
      this.getList(1)
    },

    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.item = selectedRows
    },

    onCategoryChange(value) {
      this.categorySelectData = value
      var reg = new RegExp(',', 'g')
      this.searchData.categoryIdStr = value
        .map((i) => {
          return i
        })
        .toString()
        .replace(reg, '_')
    },
  },
}
</script>

<style lang="scss">
.product-choose {
}
</style>
